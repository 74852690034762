@font-face {
  font-family: 'Press';
  src: local('Press'), url(./Press_Start_2P/PressStart2P-Regular.ttf) format('ttf');
}

body {
  background-color: black;
}

.App {
  text-align: center;
}

.wordTag {
  font-size: 20px;
  /* border: 0.5px solid white; */
  /* margin-left: 100px; */
  /* margin-top: 170px; */
  /* position:fixed; */
  position: absolute;
  /* top: 100%; */
  color: black;
  background-color: red;
  white-space: nowrap;
  font-weight: bolder;
  /* top: 50vh; */
  opacity: 0;
  pointer-events: none;
}

.projectName:hover + .wordTag {
  opacity: 1;
  display: flex;
}

.titleButton {
  color: red
}

.titleButton:hover {
  color: white
}

.titleHover {
  background-color: red;
  font-size: 24px;
  margin-top: 35px;
}

.hiddenDescription {
  display: none;
  position:fixed;
  background-color: yellow;
  margin-top: -200px;
}

.hiddenDescription h1 {
  font-size: 16px;
}

/* HOVERING YELLOW DESCRIPTION TAG */
/* .titleHover:hover + .hiddenDescription {
  display: block;
} */

#bgContainer {
  width: 100vw;
  height: 100vh;
  background-color: black;
}

#dvdMarquee {
  height: 100vh;
  width: 100vw;
  /* border: 1px solid green; */
  top: 0;
  bottom: 0;
  z-index: 999;
  left: 0;
  right: 0;
}

/* .Marquee {
  height: 100vh;
  width: 100vw;
  border: 1px solid green;
  top: 0;
  bottom: 0;
  z-index: 999;
  left: 0;
  right: 0;
} */

#contentDiv {
  width: 100vw;
  min-height: 100vh;
  top: 0;
  overflow-y: scroll;
  overflow-x: hidden;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
}

h1 {
  color: white;
  font-size: 16px;
  text-align: center;
  mix-blend-mode: normal;
  margin: 0;
}

h2 {
  color: white;
  font-size: 18px;
  font-weight: 800;
  text-align: center;
  mix-blend-mode: exclusion;
  margin: 0;
}

h1:hover {
  color: red;
}

h6 {
  mix-blend-mode: normal;
}

:link {
  color: white;
  font-size: 16px;
  text-align: center;
  mix-blend-mode: exclusion;
  margin: 0;
  text-decoration: none;
}

#contactMarquee {
  color: white
}

.workItem {
  width: 200px;
}

.workItemHover .workOverlay {
  position:fixed;
  /* top: 12%; left: 0px; */
  display: none;
  margin-left: 40%;
}

.workItemHover:hover .workOverlay {
  display: block;
}

.horizontal-scroll {
  width: 100%;
  overflow: hidden;
  position: relative;
}

.scroll-content {
  display: flex;
  white-space: normal;
  overflow-x: auto;
  align-items: center;

  /* Hide the scrollbar */
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE/Edge */
  scrollbar-color: transparent transparent; /* Firefox */
  -webkit-overflow-scrolling: touch; /* iOS Safari */
}

.scroll-content::-webkit-scrollbar {
  display: none;
}

.horizontal-marquee {
  width: 100vw;
  overflow: hidden;
  position: relative;
  /* border: 1px solid green; */
}

.marquee-content {
  white-space: nowrap;
  display: block;
  animation: marquee 10s linear infinite; /* Adjust the animation duration as needed */
  animation-delay: -5s;
}

@keyframes marquee {
  0% {
    transform: translateX(100vw); /* Start from the edge of the window */
  }
  100% {
    transform: translateX(-100vw);
  }
}

.horizontal-carousel {
  display: flex;
  flex-direction: row;
  overflow: hidden;
  position: relative;
  width: 100%; /* Adjust the width as needed */
}

.carousel-container {
  display: flex;
  overflow: hidden;
}

.carousel-wrapper {
  display: flex;
  flex-direction: row;
  transition: transform 0.5s ease-in-out; /* Adjust the transition duration as needed */
}

.carousel-item {
  flex: 0 0 auto;
  width: 300px; /* Adjust the item width as needed */
  margin-right: 20px; /* Adjust the margin as needed */
  /* Add any other styles for your items here */
}

.timeline-container {
  width: 100%;
  overflow: hidden;
}

.timeline {
  display: flex;
  flex-direction: row;
  overflow: auto;
  scrollbar-width: none;
  white-space: nowrap;
  padding: 20px;
}

.event {
  flex: 0 0 200px; /* Set the desired width for each event */
  background-color: #f0f0f0;
  margin-right: 10px;
  padding: 10px;
}

.year {
  font-weight: bold;
}

.event-description {
  font-style: italic;
}

/* Hide the scrollbar */
.timeline::-webkit-scrollbar {
  display: none;
}

.hover-element {
  position: relative;
  /* display: inline-block; */
}

.popup-overlay {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  cursor: pointer; /* Optional: Change the cursor to indicate interactivity */
}

.hover-element:hover .popup-overlay {
  display: flex;
}

.popup-overlay img {
  max-width: 80%; /* Adjust the size of the image */
  max-height: 80%;
  object-fit: contain;
  /* border: 1px solid green; */
}

.invert-on-hover:hover {
  filter: invert(100%);
}

p {
  color: white;
  font-size: 11px;
  text-transform: uppercase;
  line-height: 18px;
}

h3 {
  color: white;
  font-size:medium;
  text-transform: uppercase;
}

h3:hover {
  color: red;
}

h4:hover {
  color: white;
  text-decoration: none;
}

p:hover {
  color: red;
}

.pdf-viewer {
  margin: 20px;
  border: 1px solid #ccc;
}

.page-controls {
  display: flex;
  justify-content: space-between;
  margin: 10px;
}

.invert {
  filter: invert(100%);
}

.bw-image {
  filter: grayscale(100%) contrast(85%);
}

.highlight {
  color: red;
  font-weight: bolder;
}

.horizontal-scroll-container {
  display: flex;
  overflow-x: scroll;
  scrollbar-width: none; /* Hide the scrollbar for a cleaner look (Firefox) */
  -ms-overflow-style: none; /* Hide the scrollbar for a cleaner look (IE/Edge) */
}

.horizontal-scroll-container::-webkit-scrollbar {
  display: none; /* Hide the scrollbar for a cleaner look (Webkit-based browsers) */
}

.scroll-item {
  flex: 0 0 auto;
  margin-right: 10px; /* Adjust the margin as needed for spacing between images */
  max-height: 100%; /* Prevent images from stretching the container vertically */
}

.speaker-pdf {
  width: 80px;
  display: inline-block;
  max-width: 100%; /* Adjust image size as needed */
  max-height: 100%; /* Prevent images from stretching the container vertically */
  margin-right: 2px;
  /* margin-right: 10px; */
}

/* .backButton:hover {
  color: blccx
} */

.back {
  z-index: 999;
}

.dvd-logo-container {
  position: absolute;
  width: 100%;
  height: 100%;
  /* border: 1px solid red; */
  /* z-index: -1; */
  opacity: 1;
}


.dvd-logo {
  position: absolute;
  width: 100px; /* Adjust the size as needed */
  height: 60px; /* Adjust the size as needed */
  background: url('./Assets/dvd_logo_white.png') no-repeat;
  background-size: contain;
  mix-blend-mode: hard-light;
}

.horizontal-scroll-container {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  overflow-x: scroll;
  display: flex;
  /* animation: scrollAnimation 15s linear infinite; Adjust duration as needed */
}

.scrollable-content {
  width: auto; /* Adjust the width based on your content */
    animation: scrollAnimation 65s linear infinite; /* Adjust duration as needed */

}

@keyframes scrollAnimation {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%); /* Scrolls the entire content */
  }
}

.hoverImage {
  display: none; /* Initially, hide the image */
  position: absolute;
  top: 150px;
  width: 500px;
  filter: grayscale(100%) contrast(85%);
}

.revealLabel {
  /* color: red;
  background-color: white; */
    color: white;
  background-color: black;

}

.revealLabel:hover + .hoverImage{
  display: block;
}

.revealLabel:hover {
  /* color: white;
  background-color: black; */
  color: red;
  background-color: white;
}

.galleryLabel {
  font-size: 14px;
  color: white;
  width: 40px;
  text-align: center;
}

.galleryLabel:hover {
  background-color: white;
  color: red;
  font-weight: bold;
}

.galleryImage {
  display: none; /* Initially, hide the image */
  position: fixed;
  top: 150px;
  width: 400px;
}

/* .galleryLabel:hover + .galleryImage {
  display: block;
} */

.galleryImg {
  width: 140px;
}

.galleryImg:hover {
  filter: invert(100%);
}

.gllryContainer {
  display: none;
  flex-direction: row;
  /* align-items: center; */
  justify-content: center;
}

.workImage {
  display: flex;
  flex-direction: row;
  /* align-items: center; */
  justify-content: center;
}

.projectName:hover + .workImage {

}

.projectName:hover + .gllryContainer {
  display: flex;
  padding-top: -50px;
}

.archiveTitle:hover + .gllryContainer{
  display: flex;
}

.archiveTitle:hover {
  color: red;
}

.divider {
  display: flex;
  flex-direction: column;
  margin-top: -10px;
}

.blink {
            animation: blinker 0.7s  infinite;
            color: red;
            font-family: sans-serif;
        }
        @keyframes blinker {
            50% {
                opacity: 0;
            }
        }